import React, { useState } from "react";
import articlestyle from "./Article.css";

const Article = (props) => {
  const article = props.article;
  return (
    <a href={article.pdf} className="ref-article" target="_blank">
      <div className="article-container">
        <div className="article-vignette-container">
          <img className="article-vignette" src={article.img_source}></img>
        </div>
        <div className="article-infos">
          <label className="article-title" id={article.id + "-name"}>
            {article.name}
          </label>
          <div className="article-description">{article.description}</div>
          <div className="article-creation-date">
            {article.type} publié le {article.creation_date}
          </div>
        </div>
      </div>
    </a>
  );
};

export default Article