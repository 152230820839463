import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import footercss from "./footer.css";

export default function Footer() {
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between bg-light m-4 one-px"></section>

      <section className="">
        <MDBContainer className="small-footer text-center text-md-start mt-3">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-2">
              <h6 className="text-uppercase fw-bold mb-4 theme-artesophro">
                <MDBIcon color="secondary" icon="gem" className="me-3" />
                Artesophro
              </h6>
              <p>
                <a
                  href="/doc/proposition-code-de-deontologie.pdf"
                  className="text-reset"
                >
                  Code de déontologie
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-2">
              <h6 className="text-uppercase fw-bold mb-4 theme-artesophro">
                Liens utiles
              </h6>
              <p className="p-li">
                <a href="/" className="text-reset">
                  La sophrologie
                </a>
              </p>
              <p className="p-li">
                <a href="/votre-particienne" className="text-reset">
                  Votre praticienne
                </a>
              </p>
              <p className="p-li">
                <a href="/pour-qui" className="text-reset">
                  Pour qui ?
                </a>
              </p>
              <p className="p-li">
                <a href="/seances" className="text-reset">
                  Séances
                </a>
              </p>
              <p className="p-li">
                <a href="/infos" className="text-reset">
                  Informations pratiques
                </a>
              </p>
              <p className="p-li">
                <a href="/actualite" className="text-reset">
                  Actualités
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-2">
              <h6 className="text-uppercase fw-bold mb-4 theme-artesophro">
                Contact
              </h6>
              <p className="p-li">
                <MDBIcon color="secondary" icon="home" className="me-3" />
                Auriol
              </p>
              <p className="p-li">
                <MDBIcon color="secondary" icon="envelope" className="me-3" />
                <a href="mailto:r.artero.sophro@gmail.com;">
                  r.artero.sophro@gmail.com
                </a>
              </p>
              <p className="p-li">
                <MDBIcon color="secondary" icon="phone" className="me-3" />
                06 80 67 61 37
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4 back-theme-artesophro"
        style={{ color: "rgba(255, 255, 255)" }}
      >
        © 2021 Copyright :
        <a className="text-reset fw-bold" href="">
          Anthony Pépin
        </a>
      </div>
    </MDBFooter>
  );
}
