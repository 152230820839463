import React, { useState } from "react";
import infosstyle from "./Infos.css";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Button from "@mui/material/Button";
import {
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBModal,
  MDBModalDialog,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
} from "mdb-react-ui-kit";
import { Phone } from "@mui/icons-material";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",

  fontSize: "calc(10px + 1.1vw)",
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 2,
  backgroundColor: "#fff",
  borderRadius: "3vw",
  color: "#65B39D",
  borderColor: "#65B39D",
  fontWeight: "400",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#65B39D",
    borderColor: "#65B39D",
    boxShadow: "none",
    color: "white",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#65B300",
    borderColor: "#65B300",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem #65B39D",
  },
});
const Infos = (props) => {
  const [centredModal, setCentredModal] = useState(false);

  const toggleShow = () => setCentredModal(!centredModal);

  const [centredModaltel, setCentredModaltel] = useState(false);

  const toggleShowtel = () => setCentredModaltel(!centredModaltel);
  return (
    <div className="page-container">
      <div className="titre">Informations pratiques</div>
      <div className="contenu">
        <div className="tarifs">
          <h3 className="infos-title"> Tarifs :</h3>
          <ul className="liste-info">
            <li className="li-sophro">Séance individuelles  : 50 euros </li>
            <li className="li-sophro">
              Séance collective (Clubs sportifs, Maison de retraite,
              Etablissement scoalire)  : tarif sur devis
            </li>
            <li className="li-sophro">Séance collective en milieu scolaire: tarif sur devis</li>
            <li className="li-sophro">
              Atelier collectif de relaxation et de gestion du stress : sur
              demande
            </li>
          </ul>
          <div className="text-infos">
            (A domicile : des frais de déplacement seront appliqués selon
            localisation)
          </div>
        </div>
        <div className="rdv">
          <h3 className="infos-title">Lieux de rendez-vous </h3>
          <ul className="liste-info">
            <li className="li-sophro">
              A votre domicile (Je me déplace chez vous dans le respect des
              règles d’hygiène en vigueur)
            </li>
            <li className="li-sophro">Sur votre lieu de travail</li>
            <li className="li-sophro">Au cabinet à Auriol</li>
            <li className="li-sophro">
              En télé-consultation (utilisation de Skype, WhatsApp, Google Meet)
            </li>
            <li className="li-sophro">Dans votre club sportif</li>
          </ul>
          <div className="text-infos">
            Moyens de paiement acceptés : Chèque, espèce
          </div>
        </div>

        <p className="end-tarif">
          Pour les séance en télé-consultation : après confirmation du rendez-
          vous, le paiement sera à effectuer par virement avant la séance.
        </p>

        <div className="coordonnees">
          <h3 className="infos-title">Coordonnées</h3>
          <div className="d-grid gap-2">
            <MDBBtn
              style={{ backgroundColor: "#6cc7ad" }}
              className="me-5"
              onClick={toggleShowtel}
            >
              <MDBIcon fas icon="phone" size="2x" />
            </MDBBtn>
            <MDBBtn
              style={{ backgroundColor: "#6cc7ad" }}
              className="me-5"
              onClick={toggleShow}
            >
              <MDBIcon fas icon="envelope" size="2x" />
            </MDBBtn>
            <MDBModal
              tabIndex="-1"
              show={centredModal}
              setShow={setCentredModal}
            >
              <MDBModalDialog centered>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Contact par mail</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={toggleShow}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <p>
                      Vous pouvez me contacter directement en m'envoyant un mail
                      à r.artero.sophro@gmail.com
                    </p>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      color="secondary"
                      onClick={toggleShow}
                      style={{ color: "#6cc7ad" }}
                    >
                      Fermer
                    </MDBBtn>
                    <MDBBtn
                      href="mailto:r.artero.sophro@gmail.com"
                      style={{ backgroundColor: "#6cc7ad" }}
                    >
                      Envoyer un mail
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
            <MDBModal
              tabIndex="-1"
              show={centredModaltel}
              setShow={setCentredModaltel}
            >
              <MDBModalDialog centered>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Contact par téléphone</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={toggleShowtel}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <p>
                      Vous pouvez me contacter directement en m'appelant au 06
                      80 67 61 37
                    </p>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      color="secondary"
                      style={{ color: "#6cc7ad" }}
                      onClick={toggleShowtel}
                    >
                      Fermer
                    </MDBBtn>
                    <MDBBtn
                      href="tel:0680676137"
                      style={{ backgroundColor: "#6cc7ad" }}
                    >
                      Téléphoner
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infos;
