import React, { useState } from "react";
import Sophrostyle from "./Sophro.css";
const Sophro = (props) => {
  return (
    <div className="page-container">
      <div className="en-qq-mots">
        <div className="titre-gauche">La sophrologie en quelques mots ...</div>
        <div className="texte-gauche">
          La sophrologie fut développée par le neuropsychiatre Alfonso Caycedo
          en 1960. Cette méthode psycho corporelle permet de restaurer un
          équilibre entre le corps et le mental. La Sophrologie est une
          pédagogie d'harmonisation du corps et de l'esprit, destinée à
          développer la conscience, d’accroître la connaissance que l'on a de
          soi et de son environnement à travers l'écoute de ses ressentis
          physiques, et émotionnels. Cette pratique permet de développer le
          potentiel d'un individu, améliorer sa condition en cas de maladie et
          d'amplifier son bien être. La sophrologie c'est la pleine conscience
          du moment présent.
        </div>
      </div>
      <div className="le-fonctionnement">
        <div className="titre-droite">Le fonctionnement ...</div>
        <div className="texte-droite">
          <ul>
            <li className="li-sophro">
              Avec des exercices de respiration et de relâchement musculaires en
              posture debout ou assise permettant d'évacuer les tensions et de
              faire émerger des ressentis
            </li>
            <li className="li-sophro">
              Avec des suggestions mentales (visualisation d'image positive)
              favorisant les prises de conscience de son potentiel
            </li>
            <li className="li-sophro">
              Un accompagnement personnalisé est mis en place en fonction de
              l'objectif que vous souhaitez travailler
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="les-applications">
          <div className="titre-gauche">Les applications ...</div>
          <div className="texte-gauche">
            <ul>
              <li className="li-sophro">
                Dans l'amélioration du quotidien : gestion du stress et tensions
                physiques, troubles du sommeil, manque de confiance en soi,
                troubles de l'attention, de la mémoire, difficulté
                d'apprentissage, troubles de l'équilibre, accompagnement de la
                fin de vie, du deuil ....
              </li>
              <li className="li-sophro">
                Accompagnement d'un comportement pulsionnel ou phobique :
                Dépendance (tabac, alcool..), Troubles alimentaire (boulimie,
                anorexie...) phobies (Animaux, transports, agoraphobie,
                claustrophobie....)
              </li>
              <li className="li-sophro">
                Accompagnement à la préparation d'un événement : Préparer un
                événement précis examens, (scolaire, permis de conduire)
                épreuves sportives (compétition) , prise de parole en public...
              </li>
              <li className="li-sophro">
                l’accompagnement d’un traitement médical : gérer les douleurs de
                maladies chroniques (fibromyalgie, dépression....) mieux
                appréhender les effets douloureux des traitements médicaux,
                devenir acteur de son rétablissement.
              </li>
              Je pratique la sophrologie en séances individuelles (à domicile ou
              en visioconférence) et collectives (clubs de sport, maisons de
              retraite , établissements scolaires, entreprise , associations,
              …).
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sophro;
