import React, { useState } from "react";
import Praticiennestyle from "./Praticienne.css";
const Praticienne = (props) => {
  return (
    <div className="page-container">
      <div className="titre">Votre praticienne</div>
      <div className="praticienne-with-photo">
        <div className="text-with-photo-container">
          <div className="text-with-photo text">
            Après 25 ans passées dans le monde de l'entreprise, ayant occupée
            différents postes, en accueil du public, en accompagnement des
            conseillés en relation avec celui-ci, en poste de coordination de
            différents services et la participation d’intégration de jeunes dans
            le monde de l'entreprise, je me suis orientée vers ce qui m'a
            toujours animé au plus profond de moi, l'accompagnement des
            personnes vers leur mieux être et leur épanouissement pour une
            vie plus harmonieuse. Parallèlement à mon travail de salarié, j'ai
            effectué des formations durant 3 ans et demi et exercé dans le
            domaine du bien être avec plusieurs pratiques : le « Wuo-Tai »
            méthode corporelle basée sur de l'ostéopathie, la danse et les
            gestes qui permet une reconnexion à son corps, souplesse, agilité et
            équilibre favorisant des prises de conscience, l'« O. E V » une
            méthode manuelle alliant ostéopathie tissulaire et médecine
            traditionnelle chinoise,  qui utilise les diapasons thérapeutique et
            vise à favoriser la détentes tissulaires de certaines zones, afin de
            restaurer un équilibre dans le corps.
          </div>
        </div>
        <div className="photo-id-praticienne-container">
          <img
            src="photos\portrait Rosine.jpg"
            className="photo-id-praticienne"
          ></img>
        </div>
      </div>
      <div className="text-with-logo">
        <div className="logo-chambre">
          <img className="logo-img" src="logo-chambre-sophro.png"></img>
        </div>
        <div className="text-container">
          <div className="text">
            Passionnée par les chevaux depuis mon plus jeunes âge, j 'ai
            pratiqué pendant de nombreuses années l'équitation, je me suis
            ensuite tournée vers l'aviron, le Qi Gong puis le Yoga et la
            randonnée. Je pratique depuis plusieurs années la méditation au
            quotidien.
          </div>
          <div className="text">
            Titulaire d'un master en psychologie, c'est naturellement, que je me
            suis tournée vers la Sophrologie, technique qui
            établie des ponts entre le mental et le physique. Cette pratique
            fait le trait d'union entre mes différentes formations, et me permet
            aujourd'hui de vous accompagner pleinement vers une
            meilleure connaissance de vous-même, de mobiliser vos propres
            ressources afin de trouver votre équilibre et votre épanouissement
            dans la vie de tout les jours.
          </div>
          <div className="text">
            Je m'engage en tant que sophrologue certifiée, membre de la chambre
            syndicale, à vous accompagner dans la confidentialité, avec
            bienveillance et non jugement.
          </div>
        </div>
      </div>
      <div className="text">
        Conformément au Code de Déontologie des Sophrologues, la sophrologie ne
        se substitue pas à un traitement médical. Elle est un accompagnement
        complémentaire. Le sophrologue ne pose pas de diagnostic.
      </div>
      <div className="text">
        Je suis adhérente à la CNPM (Chambre Nationale des Praticiens de la Médiation).
        <br/><i>article L.612-1 du Code de la consommation</i>
      </div>
    </div>
  );
};

export default Praticienne;
