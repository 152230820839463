import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./App.css";
import { React, useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import Sophro from "./pages/Sophro";
import Praticienne from "./pages/Praticienne";
import PourQui from "./pages/Pour-qui";
import IconButton from "@mui/material/IconButton";
import Seances from "./pages/Seances";
import Infos from "./pages/Infos";
import Footer from "./pages/footer";
import Actu from "./pages/Actu";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import Pdf from "./doc/proposition-code-de-deontologie.pdf";
function App() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const handleNavBar = () => {
    if (window.innerWidth < 1000) {
      setIsNavExpanded(!isNavExpanded);
      document.getElementsByClassName("ul-nav")[0].style.transform =
        "scaleY(0)";
    }
  };

  return (
    <div className="navbar-container">
      <div className="fond-montagne-container">
        <div className="logo-container">
          <img
            className="logo-artesophro"
            src="/photos/logo artesophro.png"
          ></img>
          <div className="titre-container">
            <div className="titre-logo">Rosine Artero</div>
            <div className="soustitre">Sophrologue certifiée</div>
          </div>
        </div>
        <img
          className="fond-montagne"
          src="/photos/photo de fond pour site.jpg"
        ></img>
      </div>
      <div className="div-navbar">
        <div className="navigation-bar">
          <ul className="ul-nav">
            <li className="li-nav">
              <Link className="navbar-items" to="/" onClick={handleNavBar}>
                La sophrologie
              </Link>
            </li>
            <li className="li-nav">
              <Link
                className="navbar-items"
                to="/votre-particienne"
                onClick={handleNavBar}
              >
                Votre praticienne
              </Link>
            </li>
            <li className="li-nav">
              <Link
                className="navbar-items"
                to="/pour-qui"
                onClick={handleNavBar}
              >
                Pour qui ?
              </Link>
            </li>
            <li className="li-nav">
              <Link
                className="navbar-items"
                to="/seances"
                onClick={handleNavBar}
              >
                Séances
              </Link>
            </li>
            <li className="li-nav">
              <Link className="navbar-items" to="/infos" onClick={handleNavBar}>
                Informations pratiques
              </Link>
            </li>
            <li className="li-nav">
              <Link className="navbar-items" to="/actualite" onClick={handleNavBar}>
                Actualités
              </Link>
            </li>
          </ul>
          <div className="unfold-navbar-collapsible">
            <MDBBtn
              className="button-nav"
              noRipple
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
                if (!isNavExpanded) {
                  document.getElementsByClassName("ul-nav")[0].style.transform =
                    "scaleY(1)";
                } else {
                  document.getElementsByClassName("ul-nav")[0].style.transform =
                    "scaleY(0)";
                }
              }}
            >
              <MDBIcon fas icon="bars" size="3x" />
            </MDBBtn>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" exact element={<Sophro></Sophro>}></Route>
        <Route
          path="/votre-particienne"
          exact
          element={<Praticienne></Praticienne>}
        ></Route>
        <Route path="/pour-qui" exact element={<PourQui></PourQui>}></Route>
        <Route path="/seances" exact element={<Seances></Seances>}></Route>
        <Route path="/infos" exact element={<Infos></Infos>}></Route>
        <Route path="/actualite" exact element={<Actu></Actu>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
