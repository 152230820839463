import React, { useState } from "react";
import seancestyle from "./Seances.css";
const Seances = (props) => {
  return (
    <div className="page-container">
      <div className="titre">Séances</div>
      <div className="seances">
        Les séances individuelles et collectives durent en moyenne 1h00 (
        première séance 1h30). Elles se déroulent en plusieurs temps :
        <ul>
          <li>un temps d’écoutes, d’échanges et de partages</li>
          <li>
            un temps de pratique d'exercices de relaxation dynamique : mouvement doux associé à la respiration
          </li>
          <li>
            un temps de pratique d'un exercice de relaxation statique (visualisation mentale positive)
          </li>
          <li>
            un temps d'écoute permettant la libre expression des ressentis
          </li>
        </ul>
        Certaines mutuelles remboursent la sophrologie : vous pouvez vous
        renseigner en suivant le lien{" "}
        <a
          href="https://www.chambre-syndicale-sophrologie.fr/mutuelle-sophrologie-sophrologue/"
          target="_blank"
        >
          de la chambre syndicale
        </a>
        .
      </div>
    </div>
  );
};

export default Seances;
