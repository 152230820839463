import Article from "./Article";
import React, { useState } from "react";
import seancestyle from "./Actu.css";
const Actu = () => {
  const articles = [
    {
      id: "1",
      name: "Sophrologie et l'équitation",
      description:
        "Le cavalier va developper ses sens, écouter ses ressentis, decoder et appréhender ses émotions. En ayant une meilleure écoute et connaissance de son état intérieur, il pourra améliorer sa relation avec son compagnon.",
      creation_date: "02/01/2023",
      img_source: "/actu/Equitation recto (flyer).jpg",
      pdf: "/doc/Sophro_et_equitation.pdf",
      type: "Flyers",
    },
    {
      id: "2",
      name: "Sophrologie avec les seniors",
      description:
        "Le senior va apprendre à mieux vivre avec les effets qui peuvent accompagner le vieillissement (les douleurs, les problèmes de sommeil ou de mémoire..) Accepter les changements physiques. Porter un nouveau regard sur soi. Rester concentré sur l'instant présent.",
      creation_date: "02/01/2023",
      img_source: "/actu/Recto Seniors 100 ppp-1.png",
      pdf: "/doc/Senior.pdf",
      type: "Flyers",
    },
    {
      id: "3",
      name: "Mes engagements",
      description:
        " J'ai rejoint Sos sophro, association  d'intérêt  général à  caractère  social, afin de contribuer à  un elan de solidarité impulsé en novembre 2020.",
      creation_date: "07/02/2023",
      img_source: "/actu/sos_sophro.png",
      pdf: "/doc/flyers lenoir numero vert.pdf",
      type: "Actu",
    },
  ];
  return (
    <div className="article-list-container">
      <div className="titre">Actualités</div>
      <div className="intro-actu">Mes spécialisations et engagements au travers des flyers que vous pouvez consulter ci dessous :</div>
      {articles &&
        articles.map((article, index) => {
          return <Article article={article}></Article>;
        })}
    </div>
  );
};

export default Actu;
