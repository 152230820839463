import React, { useState } from "react";
import pourquistyle from "./Pour-qui.css";
const PourQui = (props) => {
  return (
    <div className="page-container">
      <div className="titre"> Pour qui ?</div>
      <div className="image droite">
        <div className="texte-gauche-img">
          <div className="title-texte-qui">
            <p className="title-qui">Les personnes âgées </p>
          </div>
          <div>
            <p className="texte-gauche-img-p">
              Gestion des difficultés engendrées par le vieillissement, mieux
              respirer, maintenir son équilibre, retrouver le sommeil....
            </p>
          </div>
        </div>

        <img
          className="img-droite"
          src="/photos/photo pour item personnes agees 1.png"
        ></img>
      </div>
      <div className="image gauche">
        <img
          className="img-gauche"
          src="/photos/photo pour item adultes 1.png"
        ></img>
        <div className="texte-gauche-img">
          <div className="title-texte-qui">
            <p className="title-qui">Les adultes </p>
          </div>
          <p className="texte-droite-img-p">
            Accompagnement dans l'amélioration du quotidien, apprendre à gérer
            ses émotions, le stress se reconnecter à son corps afin de trouver
            un équilibre .
          </p>
        </div>
      </div>
      <div className="image droite">
        <div className="texte-gauche-img">
          <div className="title-texte-qui">
            <p className="title-qui">Les adolescents </p>
          </div>{" "}
          <p className="texte-gauche-img-p">
            Accompagnement dans cette étape de transition entre l'enfance et
            l'âge adulte pour répondre au stress , aux angoisses et fatigue
            générée par de nombreuses transformations corporelles et recherche
            de son identité, découragement et perte de confiance en soi.
          </p>
        </div>

        <img
          className="img-droite"
          src="/photos/photos pour item adolescent 1.png"
        ></img>
      </div>
      <div className="image gauche">
        <img
          className="img-gauche"
          src="/photos/photo pour item enfants 1.png"
        ></img>
        <div className="texte-gauche-img">
          {" "}
          <div className="title-texte-qui">
            <p className="title-qui">Les enfants (à partir de 6 ans) : </p>
          </div>
          <p className="texte-droite-img-p">
            Accompagnement pour des phobie scolaire, timidité, problème de
            concentration de mémorisation, hyperactivité....
          </p>
        </div>
      </div>
      <div className="image droite">
        <div className="texte-gauche-img">
          {" "}
          <div className="title-texte-qui">
            <p className="title-qui">Les sportifs : </p>
          </div>
          <p className="texte-gauche-img-p">
            Amateur ou professionnel, accompagnement pour vous permettre
            d'améliorer votre potentiel, connaissance et maîtrise de son corps
            afin de mieux gérer les efforts, les douleurs musculaire
            concentration, motivation
          </p>
        </div>
        <img
          className="img-droite"
          src="/photos/photos pour item sport 1.png"
        ></img>
      </div>
      <div className="image gauche">
        <img
          className="img-gauche"
          src="/photos/photo pour item entreprise A 1 (1).png"
        ></img>
        <div className="texte-gauche-img">
          {" "}
          <div className="title-texte-qui">
            <p className="title-qui">Les entreprises : </p>
          </div>
          <p className="texte-droite-img-p">
            Comme moyen de prévention des risques psychosociaux (stress ,
            épuisement professionnel) gestion du stress, de la pression,
            prévention des Troubles Musculo Squelettiques, cohésion du travail
            en équipe, adaptation aux changements, aide à la prise de recul et à
            la prise de décision, gestion du temps et priorisation des
            actions.....
          </p>
        </div>
      </div>
      <div className="image droite">
        <div className="texte-gauche-img">
          {" "}
          <div className="title-texte-qui">
            <p className="title-qui">Le milieu de la santé : </p>
          </div>
          <p className="texte-gauche-img-p">
            En accompagnement de traitement médical, dans le cas de certaines
            pathologies : acouphène, fibromyalgie, cancer, dépression, sclérose
            en plaque, Alzheimer ...
          </p>
        </div>
        <img
          className="img-droite"
          src="/photos/photo pour lr milieu de la sant_ 1.png"
        ></img>
      </div>
    </div>
  );
};

export default PourQui;
